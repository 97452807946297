import styled, { css } from 'styled-components';

import { ButtonOrLink } from 'components/ButtonOrLink/ButtonOrLink';
import { colors, cssBreakpoints, shadows, transition } from 'theme/theme';
import {
  SEARCH_HIT_IMAGE_SIZE,
  SEARCH_HIT_IMAGE_SIZE_SMALL,
  SEARCH_HIT_SIDEBAR_IMAGE_HEIGHT,
  SEARCH_HIT_SIDEBAR_IMAGE_WIDTH,
} from 'utils/constants/searchHitImageSizes';

const searchHitOneWhenHoveredElseZeroVar = '--search-hit-hovered';
const searchHitTopBorderRadius = '--search-hit-top-border-radius';

export const SearchHitHolder = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SearchHitCard = styled.div`
  position: relative;
`;

export const SearchHitLink = styled(ButtonOrLink)<{
  $variant: 'search' | 'sidebar';
  $roundedTop: boolean;
  $highlighted: boolean;
}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  color: inherit;
  text-decoration: none;
  box-shadow: ${(props) =>
    props.$highlighted ? `0 0 0 2px ${colors.mediumSkyBlue}` : shadows.low};
  transition: ${transition};
  background: ${colors.white};
  cursor: pointer;

  ${searchHitTopBorderRadius}: ${(props) => (props.$roundedTop ? '4px' : 0)};

  border-radius: var(${searchHitTopBorderRadius})
    var(${searchHitTopBorderRadius}) 4px 4px;

  pointer-events: ${(props) => (props.$highlighted ? 'none' : undefined)};

  ${searchHitOneWhenHoveredElseZeroVar}: 0;

  &:hover,
  &:focus {
    ${(props) =>
      !props.$highlighted &&
      css`
        ${searchHitOneWhenHoveredElseZeroVar}: 1
      `};
  }

  ${(props) => {
    switch (props.$variant) {
      case 'search':
        return css`
          padding: 20px;

          @media all and (min-width: ${cssBreakpoints.xsUp}) {
            padding: 30px 16px 30px 30px;
            gap: 30px;
            flex-direction: row;
          }
        `;
      case 'sidebar':
        return css`
          gap: 20px;
          padding: 10px;
          padding-left: 20px;

          @media all and (min-width: ${cssBreakpoints.lgUp}) {
            flex-direction: row;
            align-items: center;
          }
        `;
    }
  }};
`;

export const SearchHitHoverEffect = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  overflow: hidden;
  pointer-events: none;

  border-top-left-radius: var(${searchHitTopBorderRadius});
  border-top-right-radius: var(${searchHitTopBorderRadius});

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 5px;
    background: linear-gradient(
      to left,
      ${colors.lemon},
      ${colors.brandYellow}
    );
    transition: all ${transition};
    transform: translateY(
      calc((var(${searchHitOneWhenHoveredElseZeroVar}) - 1) * 100%)
    );
  }
`;

export const SearchHitActivelyHiringBadgeContainer = styled.div`
  margin-left: 10px;
`;

export const SearchHitImageHolder = styled.div<{
  $variant: 'search' | 'sidebar';
  $hiddenOnMobile: boolean;
}>`
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 100%;

  width: ${SEARCH_HIT_IMAGE_SIZE_SMALL}px;
  height: ${SEARCH_HIT_IMAGE_SIZE_SMALL}px;

  @media all and (max-width: ${cssBreakpoints.xsDown}) {
    ${(props) => (props.$hiddenOnMobile ? 'none' : undefined)};
  }

  ${(props) => {
    switch (props.$variant) {
      case 'search':
        return css`
          @media all and (min-width: ${cssBreakpoints.xsUp}) {
            width: ${SEARCH_HIT_IMAGE_SIZE}px;
            height: ${SEARCH_HIT_IMAGE_SIZE}px;
            display: flex;
          }
        `;
      case 'sidebar':
        return css`
          @media all and (min-width: 1440px) {
            width: ${SEARCH_HIT_SIDEBAR_IMAGE_WIDTH}px;
            height: ${SEARCH_HIT_SIDEBAR_IMAGE_HEIGHT}px;
            display: flex;
          }
        `;
    }
  }};
`;

/**
 * The save button cannot be within the `container-type` div because that causes
 * the tooltip to be below the next search result tile
 */
export const SearchHitSaveButtonHolder = styled.div<{
  $variant: 'search' | 'sidebar';
}>`
  position: absolute;

  ${(props) => {
    switch (props.$variant) {
      case 'search':
        return css`
          top: 30px;
          right: 20px;
        `;
      case 'sidebar':
        return css`
          top: 15px;
          right: 10px;
        `;
    }
  }};
`;
