import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { FilestackImage } from 'components/FilestackImage/FilestackImage';
import { ActionOppsIcon } from 'components/Icon/ActionOpps/ActionOppsIcon';
import { ActionOppsIconName } from 'components/Icon/ActionOpps/ActionOppsIconName';
import { SEARCH_HIT_IMAGE_SIZE } from 'utils/constants/searchHitImageSizes';

import { SearchHitImageLogo } from './SearchHitImageLogo';

type Props = {
  isImported: boolean;
  imageUrl: string | undefined;
  image: ApiFilestackImageData | undefined;
  source: string | undefined;
  name: string;
  logo: ApiFilestackImageData | undefined;
};

export function SearchHitImage({
  isImported,
  imageUrl,
  image,
  source,
  name,
  logo,
}: Props) {
  if (isImported && !imageUrl && source) {
    const actionOppsIconName = source
      .toLowerCase()
      .replace(/_/g, '-') as ActionOppsIconName;

    return (
      <ActionOppsIcon
        name={actionOppsIconName}
        width={SEARCH_HIT_IMAGE_SIZE}
        maxWidth="100%"
        maxHeight="100%"
      />
    );
  }

  if (imageUrl) {
    return (
      <img
        src={imageUrl}
        alt=""
        width="100%"
        height="100%"
        style={{
          objectFit:
            // Grapevine images have a landscape aspect ratio, others are portrait
            source?.toLowerCase() === 'grapevine' ? 'cover' : 'contain',
          objectPosition: 'center center',
        }}
      />
    );
  }

  if (image) {
    return (
      <FilestackImage
        data={image}
        width={SEARCH_HIT_IMAGE_SIZE}
        expandSize
        objectPosition="top center"
        noCrop
      />
    );
  }

  return (
    <SearchHitImageLogo
      width={SEARCH_HIT_IMAGE_SIZE}
      height={SEARCH_HIT_IMAGE_SIZE}
      name={name}
      logo={logo}
    />
  );
}
