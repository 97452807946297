import { useCallback } from 'react';
import { sprintf } from 'sprintf-js';
import styled from 'styled-components';

import { Box } from 'components/Box/Box';
import { LegacyTertiaryButton } from 'components/Button/LegacyTertiaryButton';
import { PrimaryButton } from 'components/Button/PrimaryButton';
import { Icon } from 'components/Icon/Icon';
import { Tooltip } from 'components/Tooltip/Tooltip';
import { useSaveListing } from 'hooks/useSaveListing';
import { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import { trackClicked } from 'utils/analytics/track/trackClicked';
import { trackEvent } from 'utils/analytics/track/trackEvent';
import { LISTING_TYPE_PLURAL_HUMAN_NAME_MAP } from 'utils/constants/general/listingTypePluralHumanNameMap';
import { deferAndStartRouteLoadingAnimation } from 'utils/ui/routeLoadingAnimation';

import { SearchHitSaveButton } from './SearchHitSaveButton';

type Props = {
  hit: SearchAlgoliaHit;
};

const ActionContainer = styled.div`
  display: flex;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;

export function SearchHitSaveButtonContainer({ hit }: Props) {
  const {
    saved,
    saveOrg,
    setShowOrgModal,
    showOrgModal,
    canSave,
    saveListing,
    unsaveListing,
  } = useSaveListing({
    listingId: hit.objectID,
    listingType: hit.type,
    listingName: hit.name,
    orgId: 'orgID' in hit ? hit.orgID : null,
    orgName: 'orgName' in hit ? hit.orgName : null,
    orgType: 'orgType' in hit ? hit.orgType : null,
  });

  const onConfirm = () => {
    saveOrg();
    trackClicked('Save Org Tooltip', { cta_type: 'yes' });
    setShowOrgModal(false);
  };

  const onDecline = () => {
    trackClicked('Save Org Tooltip', { cta_type: 'no' });
    setShowOrgModal(false);
  };

  const onRender = useCallback(
    () => trackEvent('Displayed: Save Org Tooltip'),
    [],
  );

  if (!canSave) return null;

  return (
    <ActionContainer>
      <Tooltip
        content={
          <div data-qa-id="save-listing-tooltip">
            <Box mb={3}>
              {sprintf(
                getText(
                  'Would you like to get alerts when this organization posts new %(listingTypeName)s?',
                ),
                {
                  listingTypeName: LISTING_TYPE_PLURAL_HUMAN_NAME_MAP[hit.type],
                },
              )}
            </Box>

            <ButtonContainer>
              <LegacyTertiaryButton
                onClick={onDecline}
                data-qa-id="decline-button"
              >
                {getText('No, thank you')}
              </LegacyTertiaryButton>
              <PrimaryButton
                onClick={onConfirm}
                disabled={false}
                data-qa-id="confirm-button"
              >
                {getText('Yes!')}
              </PrimaryButton>
            </ButtonContainer>
          </div>
        }
        placement="bottom-left"
        closeable
        size="large"
        variant="brand-blue"
        renderCondition={showOrgModal}
        onRender={onRender}
      >
        <SearchHitSaveButton
          $saved={saved}
          onClick={() =>
            deferAndStartRouteLoadingAnimation(
              saved ? unsaveListing : saveListing,
            )
          }
          title={saved ? getText('Unsave') : getText('Save')}
          data-qa-id={saved ? 'unsave-listing' : 'save-listing'}
        >
          <Icon name={saved ? 'bookmark-filled' : 'bookmark'} size={16} />
        </SearchHitSaveButton>
      </Tooltip>
    </ActionContainer>
  );
}
