import { fromUnixTime } from 'date-fns';
import { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { sprintf } from 'sprintf-js';

import { ApiFilestackImageData } from 'api/types/ApiFilestackImageData';
import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { ActivelyHiringBadge } from 'components/ActivelyHiringBadge/ActivelyHiringBadge';
import { ButtonOrLinkData } from 'components/ButtonOrLink/ButtonOrLinkData';
import { IdealistLogoOnDemand } from 'components/Logo/IdealistLogoOnDemand';
import { SearchAlgoliaHit } from 'modules/search/algolia/types/SearchAlgoliaHit';
import { SearchContextHeaderLocationState } from 'modules/search/containers/SearchContextHeader/SearchContextHeaderLocationState';
import { getImportedListingUrl } from 'modules/search/helpers/getImportedListingUrl';
import { SearchFacet } from 'modules/search/types/SearchFacet';
import { useUserEnvironment } from 'store/hooks/useUserEnvironment';
import { getCityStateString } from 'utils/address/getCityStateString';
import { VOLOP_IMPORTED_TYPE_MAP } from 'utils/constants/general/volopImportedTypeMap';
import { distanceInWordsToNow } from 'utils/date';
import { getCountryName, getUsStateName } from 'utils/internationalization';
import { formatSalaryRangeWithPeriodHumanName } from 'utils/listings';

import { SearchHitImage } from './Image/SearchHitImage';
import { SearchHitInfo } from './Info/SearchHitInfo';
import { SearchHitOwnerOrgBanner } from './OrgOwnerBanner/SearchHitOwnerOrgBanner';
import { SearchHitSaveButtonContainer } from './SaveButton/SearchHitSaveButtonContainer';
import {
  SearchHitActivelyHiringBadgeContainer,
  SearchHitCard,
  SearchHitHolder,
  SearchHitHoverEffect,
  SearchHitImageHolder,
  SearchHitLink,
  SearchHitSaveButtonHolder,
} from './SearchHit.styled';

type Props = {
  hit: SearchAlgoliaHit;
  trackClick?: () => void;
  showLabel?: boolean;
  hidePublished?: boolean;
  hideCta?: boolean;
  hideOwnerBanner?: boolean;
  highlighted?: boolean;
  application?: {
    status: 'ACTIVE' | 'INACTIVE';
    created: ApiISODateTimeString;
  };
  variant: 'search' | 'sidebar';
  searchContextFacets?: SearchFacet[];
};

export function SearchHit({
  hit,
  trackClick,
  showLabel,
  hidePublished,
  hideCta,
  hideOwnerBanner,
  highlighted,
  application,
  variant,
  searchContextFacets: searchContextFacetsProp,
}: Props) {
  const locationStateSearchContext =
    useLocation<SearchContextHeaderLocationState>().state?.showSearchContext;

  const searchContextFacets =
    searchContextFacetsProp || locationStateSearchContext?.searchFacets;

  const { user } = useUserEnvironment();
  const { name, type, logo, city, state, country, objectID } = hit;

  // This is not very developer friendly, it's done this way because it
  // provides type safety.
  //
  // Previously we were destructing `{ published, ... } = hit` which raises
  // TS issues and breaks type checking.
  const published = 'published' in hit ? hit.published : undefined;

  // TODO: remove imageHandle after we re-index volops and events
  const imageHandle = 'imageHandle' in hit ? hit.imageHandle : undefined;
  const imageHandleImage = imageHandle ? { handle: imageHandle } : undefined;

  const image =
    'image' in hit ? (hit.image as ApiFilestackImageData) : undefined;
  const imageUrl = 'imageUrl' in hit ? hit.imageUrl : undefined;
  const orgType = 'orgType' in hit ? hit.orgType : undefined;
  const jobType = 'jobType' in hit ? hit.jobType : undefined;
  const locationType = 'locationType' in hit ? hit.locationType : undefined;
  const remoteZone = 'remoteZone' in hit ? hit.remoteZone : undefined;
  const startDate = 'startDate' in hit ? hit.startDate : undefined;
  const startTime = 'startTime' in hit ? hit.startTime : undefined;
  const startsLocal = 'startsLocal' in hit ? hit.startsLocal : undefined;
  const endDate = 'endDate' in hit ? hit.endDate : undefined;
  const endTime = 'endTime' in hit ? hit.endTime : undefined;
  const endsLocal = 'endsLocal' in hit ? hit.endsLocal : undefined;
  const timezone = 'timezone' in hit ? hit.timezone : undefined;
  const amountRaised = 'amountRaised' in hit ? hit.amountRaised : undefined;
  const amountRequested =
    'amountRequested' in hit ? hit.amountRequested : undefined;
  const signatureCount =
    'signatureCount' in hit ? hit.signatureCount : undefined;
  const goal = 'goal' in hit ? hit.goal : undefined;
  const source = 'source' in hit ? hit.source : undefined;
  const actionType = 'actionType' in hit ? hit.actionType : undefined;
  const isPostedAnonymously =
    'isPostedAnonymously' in hit ? hit.isPostedAnonymously : undefined;
  const hiring = 'hiring' in hit ? hit.hiring : undefined;
  const salaryMinimum = 'salaryMinimum' in hit ? hit.salaryMinimum : undefined;
  const salaryMaximum = 'salaryMaximum' in hit ? hit.salaryMaximum : undefined;
  const salaryPeriod = 'salaryPeriod' in hit ? hit.salaryPeriod : undefined;
  const salaryCurrency =
    'salaryCurrency' in hit ? hit.salaryCurrency : undefined;
  const isIdealistDay = 'isIdealistDay' in hit ? hit.isIdealistDay : undefined;

  const amountCurrent = amountRaised || signatureCount;
  const amountTotal = amountRequested || goal;

  const location = useMemo(() => {
    if (remoteZone !== undefined && remoteZone === 'WORLD') {
      return getText('Anywhere');
    }

    if (remoteZone !== undefined && remoteZone === 'COUNTRY') {
      return getCountryName(country);
    }

    if (remoteZone !== undefined && remoteZone === 'STATE') {
      return getUsStateName(state);
    }

    return getCityStateString({
      city,
      stateCode: state,
      country,
    });
  }, [city, country, remoteZone, state]);

  const isImported = type === 'IMPORTED';

  const listingStats = useMemo(() => {
    const stats: ReactNode[] = [];

    if (
      published &&
      !hidePublished &&
      !(source && ['DO_SOMETHING', 'GRAPEVINE'].includes(source))
    ) {
      stats.push(
        sprintf(getText('Posted %(postedAt)s'), {
          postedAt: distanceInWordsToNow(fromUnixTime(published)),
        }),
      );

      if (hiring) {
        stats.push(
          <SearchHitActivelyHiringBadgeContainer>
            <ActivelyHiringBadge />
          </SearchHitActivelyHiringBadgeContainer>,
        );
      }
    }

    if (
      published &&
      !hidePublished &&
      source &&
      ['DO_SOMETHING', 'GRAPEVINE'].includes(source)
    ) {
      stats.push(
        /* TRANSLATORS Ongoing */
        getText('Ongoing'),
      );
    }

    if (isImported && hit.source) {
      stats.push(VOLOP_IMPORTED_TYPE_MAP[hit.source]);
    }

    if (actionType && !isImported) {
      stats.push(<IdealistLogoOnDemand withText height={20} />);
    }

    return stats;
  }, [actionType, hidePublished, hiring, hit, isImported, published, source]);

  const showSaveButton =
    !hideCta &&
    ['ORG', 'JOB', 'VOLOP', 'EVENT', 'INTERNSHIP', 'IMPORTED'].includes(type);

  const orgId = type === 'ORG' ? objectID : hit.orgID;
  let orgName = type === 'ORG' ? name : hit.orgName;
  if (type === 'IMPORTED' && source === 'VOLUNTEERGOV') {
    orgName = hit.orgName
      ? `${hit.siteName} (${hit.orgName})`
      : `${hit.siteName}`;
  }
  const ownerOrg = user ? user.orgs.find((o) => o.id === orgId) : null;
  const startDateTime = startDate && startTime ? startsLocal : null;
  const endDateTime = endDate && endTime ? endsLocal : null;

  const salaryFormatted = useMemo(
    () =>
      salaryPeriod && (salaryMinimum || salaryMaximum)
        ? formatSalaryRangeWithPeriodHumanName(
            salaryMinimum ? parseFloat(salaryMinimum) : null,
            salaryMaximum ? parseFloat(salaryMaximum) : null,
            country,
            salaryCurrency,
            salaryPeriod,
          )
        : '',
    [salaryMinimum, salaryMaximum, country, salaryCurrency, salaryPeriod],
  );

  const linkData: ButtonOrLinkData = useMemo(() => {
    if (hit.type === 'IMPORTED' || typeof hit.url === 'string') {
      return {
        type: 'link',
        href:
          hit.type === 'IMPORTED'
            ? getImportedListingUrl(hit.url, hit.source)
            : (hit.url as string),
      };
    }

    return {
      type: 'link-with-state',
      to: hit.url[CURRENT_LOCALE],
      state: searchContextFacets
        ? ({
            showSearchContext: { searchFacets: searchContextFacets },
          } satisfies SearchContextHeaderLocationState)
        : {},
    };
  }, [hit, searchContextFacets]);

  return (
    <SearchHitHolder data-qa-id="search-result" data-hit-id={hit.objectID}>
      {!hideOwnerBanner && ownerOrg && (
        <SearchHitOwnerOrgBanner org={ownerOrg} hit={hit} />
      )}

      <SearchHitCard>
        <SearchHitLink
          $variant={variant}
          $roundedTop={hideOwnerBanner || !ownerOrg}
          $highlighted={Boolean(highlighted)}
          data={linkData}
          onClick={() => trackClick?.()}
        >
          <SearchHitHoverEffect />

          <SearchHitImageHolder
            $hiddenOnMobile={isImported || !logo}
            $variant={variant}
          >
            <SearchHitImage
              isImported={isImported}
              imageUrl={imageUrl}
              image={image || imageHandleImage}
              source={source}
              name={name}
              logo={logo || undefined}
            />
          </SearchHitImageHolder>

          <SearchHitInfo
            actionType={actionType}
            amountCurrent={amountCurrent}
            amountTotal={amountTotal}
            application={application}
            endDateTime={endDateTime}
            hiring={hiring}
            jobType={jobType}
            isImported={isImported}
            isPostedAnonymously={isPostedAnonymously}
            listingStats={listingStats}
            location={location}
            locationType={locationType}
            name={name}
            orgName={orgName}
            orgType={orgType}
            salaryFormatted={salaryFormatted}
            showLabel={showLabel}
            startDateTime={startDateTime}
            timezone={timezone}
            type={type}
            variant={variant}
            isIdealistDay={isIdealistDay}
          />
        </SearchHitLink>

        {showSaveButton && (
          <SearchHitSaveButtonHolder $variant={variant}>
            <SearchHitSaveButtonContainer hit={hit} />
          </SearchHitSaveButtonHolder>
        )}
      </SearchHitCard>
    </SearchHitHolder>
  );
}
