import styled, { css } from 'styled-components';

import { colors } from 'theme/theme';

export const SearchHitInfoBoxListingLabelsHolder = styled.div<{
  $variant: 'search' | 'sidebar';
}>`
  border-bottom: 1px solid ${colors.calloutGrey};

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;

  ${(props) => {
    switch (props.$variant) {
      case 'search':
        return css`
          padding: 15px 0 10px;
          margin-bottom: 10px;
        `;
      case 'sidebar':
        return css`
          padding: 10px 0;
          margin-bottom: 10px;
        `;
    }
  }};
`;
