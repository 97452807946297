import { Fragment, ReactNode } from 'react';
import { sprintf } from 'sprintf-js';

import { ApiListingLocationType } from 'api/types/ApiListingLocationType';
import { ApiISODateTimeString } from 'api/types/ApiTypedDate';
import { Box } from 'components/Box';
import { FundingMeter } from 'components/FundingMeter';
import { IdealistDayLabel } from 'components/IdealistDays/IdealistDayLabel';
import { LegacyTooltip } from 'components/LegacyTooltip';
import { BodyMicro } from 'components/Text/BodyMicro';
import { ApiJobType } from 'modules/listing/api/job/types/ApiJobType';
import { ApiActionType } from 'modules/listing/api/volop/types/ApiActionType';
import { ListingStatusLabel } from 'modules/listing/components/ListingStatusLabel/ListingStatusLabel';
import { ListingTypeLabel } from 'modules/listing/components/ListingTypeLabel/ListingTypeLabel';
import { shorterDate } from 'utils/date';

import { SearchHitInfoBoxListingLabels } from './ListingLabels/SearchHitInfoBoxListingLabels';
import {
  SearchHitInfoFundingMeterHolder,
  SearchHitInfoHeader,
  SearchHitInfoHeaderHeading,
  SearchHitInfoHeaderOrgName,
  SearchHitInfoHolder,
  SearchHitInfoPipe,
  SearchHitInfoStats,
} from './SearchHitInfo.styled';

type Props = {
  actionType?: ApiActionType | null | undefined;
  amountCurrent?: number | null;
  amountTotal?: number | null;
  application?: {
    status: 'ACTIVE' | 'INACTIVE';
    created: ApiISODateTimeString;
  };
  endDateTime?: ApiISODateTimeString | null;
  hiring?: boolean;
  jobType?: ApiJobType[];
  isImported?: boolean;
  isPostedAnonymously?: boolean;
  location?: string;
  locationType?: ApiListingLocationType;
  listingStats: ReactNode[];
  name: string;
  orgName?: string | null;
  orgType?: string | null;
  salaryFormatted?: string;
  showLabel?: boolean;
  startDateTime?: ApiISODateTimeString | null;
  timezone?: string | null;
  type: 'ORG' | 'JOB' | 'INTERNSHIP' | 'VOLOP' | 'EVENT' | 'IMPORTED';
  variant: 'search' | 'sidebar';
  isIdealistDay?: boolean;
};

export function SearchHitInfo({
  actionType,
  amountCurrent,
  amountTotal,
  application,
  endDateTime,
  hiring,
  jobType,
  isImported,
  isPostedAnonymously,
  location,
  locationType,
  listingStats,
  name,
  orgName,
  orgType,
  salaryFormatted,
  showLabel,
  startDateTime,
  timezone,
  type,
  variant,
  isIdealistDay,
}: Props) {
  return (
    <SearchHitInfoHolder $variant={variant}>
      {showLabel && (
        <div style={{ marginBottom: 14 }}>
          <ListingTypeLabel listingType={type} actionType={actionType} />
        </div>
      )}

      {isIdealistDay && (
        <Box mb="12px">
          <IdealistDayLabel />
        </Box>
      )}

      <SearchHitInfoHeader $variant={variant}>
        <SearchHitInfoHeaderHeading>
          <div>
            <span data-qa-id="search-result-link">{name.slice(0, 120)}</span>
            {name.length > 120 && <>&hellip;</>}
          </div>

          {isPostedAnonymously && (
            <Box display="inline-block" ml="4px" verticalAlign="middle">
              <LegacyTooltip width={360}>
                {getText(
                  `The hiring organization has chosen to publish this listing anonymously to maintain confidentiality during the hiring process. They have provided Idealist with all of their organization’s details and currently meet our posting guidelines.`,
                )}
              </LegacyTooltip>
            </Box>
          )}
        </SearchHitInfoHeaderHeading>

        {(orgType === 'CORPORATION' || (type !== 'ORG' && orgName)) && (
          <SearchHitInfoHeaderOrgName $variant={variant}>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              {orgName}
            </Box>
          </SearchHitInfoHeaderOrgName>
        )}
      </SearchHitInfoHeader>

      <SearchHitInfoBoxListingLabels
        listingType={type}
        location={location}
        jobType={jobType}
        locationType={locationType}
        salaryFormatted={salaryFormatted}
        timezone={timezone}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        variant={variant}
      />

      {(listingStats.length > 0 || application) && (
        <SearchHitInfoStats>
          {listingStats.map((info, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={index}>
              {((index > 0 && !hiring) || (index > 1 && hiring)) && (
                <SearchHitInfoPipe />
              )}
              <BodyMicro lineHeight={1}>{info}</BodyMicro>
            </Fragment>
          ))}

          {application && (
            <>
              <ListingStatusLabel
                listingStatus={application.status}
                asTag
                forceSolid
              />

              <Box display="inline-block" ml={12}>
                <BodyMicro>
                  {sprintf(getText('Applied %(date)s'), {
                    date: shorterDate(application.created),
                  })}
                </BodyMicro>
              </Box>
            </>
          )}
        </SearchHitInfoStats>
      )}

      {isImported &&
        typeof amountTotal === 'number' &&
        typeof amountCurrent === 'number' && (
          <SearchHitInfoFundingMeterHolder>
            <FundingMeter
              total={amountTotal}
              current={amountCurrent}
              dollars={Boolean(
                actionType && ['DONATION', 'LOAN'].includes(actionType),
              )}
              signatures={actionType === 'PETITION'}
            />
          </SearchHitInfoFundingMeterHolder>
        )}
    </SearchHitInfoHolder>
  );
}
