import { sprintf } from 'sprintf-js';

import { OrgDashboardApiOrg } from 'modules/orgDashboard/api/types/OrgDashboardApiOrg';
import { getSalaryPeriodHumanName } from 'utils/constants/general/salaryPeriods';

type PaidListingType = 'JOB' | 'INTERNSHIP';

// Form helper
export function listingPriceInfo(
  formValues: {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    address?: Record<string, any>;
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    addresses?: Array<Record<string, any>>;
  },
  listingType: PaidListingType,
  org: OrgDashboardApiOrg,
) {
  const price = org.prices ? org.prices.listings[listingType] : null;
  const promoCredits = org.promoCredits[listingType];
  const credits = org.credits[listingType];
  const numAddresses = formValues.addresses ? formValues.addresses.length : 1;
  return {
    dollarPrice: price ? price * numAddresses : 0,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line no-nested-ternary
    creditPrice: price ? (promoCredits || credits ? numAddresses : null) : 0,
  };
}

// Form helper
export function pricingMessage(
  formValues: {
    address?: Record<string, unknown>;
    addresses?: Array<Record<string, unknown>>;
  },
  listingType: PaidListingType,
  org: OrgDashboardApiOrg,
) {
  const { dollarPrice, creditPrice } = listingPriceInfo(
    formValues,
    listingType,
    org,
  );
  return (
    /* TRANSLATORS : in "add to cart" button when org admin creates new listing */
    creditPrice
      ? sprintf(
          getText('$%(dollarPrice)s USD or %(creditPrice)s %(creditLabel)s'),
          {
            dollarPrice,
            creditPrice,
            creditLabel:
              creditPrice > 1 ? getText('credits') : getText('credit'),
          },
        )
      : `$${dollarPrice} USD`
  );
}

// TODO: refactor into generic helper
export function formatSalary(
  salary: number,
  _country: string,
  currency?: string | null,
) {
  const hasDecimal = salary % 1 > 0;

  // round salary unless it's hourly, in which case allow decimals
  const intSalary = hasDecimal ? Number(salary) : Math.round(salary);

  if (currency) {
    if (currency === 'USD') {
      const dollarString = intSalary.toLocaleString(CURRENT_LOCALE, {
        minimumFractionDigits: hasDecimal ? 2 : 0,
      });

      // String.prototype.toLocaleString() uses non-breaking spaces
      // so we're setting it manually here for the sake of consistency
      return `USD\u00A0$${dollarString}`;
    }

    return intSalary.toLocaleString(CURRENT_LOCALE, {
      style: 'currency',
      currency,
      currencyDisplay: 'code',
      minimumFractionDigits: hasDecimal ? 2 : 0,
    });
  }

  return intSalary.toLocaleString(CURRENT_LOCALE, {
    minimumFractionDigits: hasDecimal ? 2 : 0,
  });
}

export function formatSalaryRange(
  salaryMinimum: number | string | null | undefined,
  salaryMaximum: number | string | null | undefined,
  country: string,
  currency: string | null | undefined,
) {
  const salaryMinimumAsNumber = Number(salaryMinimum);
  const salaryMaximumAsNumber = Number(salaryMaximum);

  if (
    salaryMinimumAsNumber &&
    salaryMaximumAsNumber &&
    salaryMinimumAsNumber !== salaryMaximumAsNumber
  ) {
    return `${formatSalary(salaryMinimumAsNumber, country, currency)} - ${
      currency === 'USD' ? '$' : ''
    }${formatSalary(salaryMaximumAsNumber, country)}`;
  }

  if (
    salaryMinimumAsNumber &&
    salaryMaximumAsNumber &&
    salaryMinimumAsNumber === salaryMaximumAsNumber
  ) {
    return formatSalary(salaryMinimumAsNumber, country, currency);
  }

  if (salaryMinimumAsNumber) {
    return sprintf(getText('At least %(salaryMinimum)s'), {
      salaryMinimum: formatSalary(salaryMinimumAsNumber, country, currency),
    });
  }

  if (salaryMaximumAsNumber) {
    return sprintf(getText('Up to %(salaryMaximum)s'), {
      salaryMaximum: formatSalary(salaryMaximumAsNumber, country, currency),
    });
  }

  return '';
}

export function formatSalaryRangeWithPeriodHumanName(
  salaryMinimum: number | string | null | undefined,
  salaryMaximum: number | string | null | undefined,
  country: string,
  currency: string | null | undefined,
  salaryPeriod: string,
) {
  const salaryText = formatSalaryRange(
    salaryMinimum,
    salaryMaximum,
    country,
    currency,
  );

  const periodText = salaryPeriod
    ? ` / ${getSalaryPeriodHumanName(salaryPeriod)}`
    : '';

  return salaryText + periodText;
}
